<template>
  <a-modal
    :width="700"
    :title="taskModalValue.status === 'ADD' ? '添加任务' : '修改任务'"
    :visible="visible"
    :confirmLoading="loading"
    okText="确认"
    cancelText="取消"
    @ok="handleOk"
    @cancel="cancelForm"
  >
    <a-form :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">

      <a-form-item label="任务类型">
        <a-select
          v-decorator="['taskType',{ initialValue: '1', rules: [{ required: true, message: '请选择任务类型' }] }]"
          placeholder="请选择任务类型"
        >
          <a-select-option value="1">
            备份数据库
          </a-select-option>
        </a-select>
      </a-form-item>
      <div style="display: flex;">
        <a-form-item label="执行周期" style="width: 200px;" :label-col="{ span: 10 }" :wrapper-col="{ span: 13 }">
          <a-select
            v-decorator="['cycle']"
            placeholder=""
            style="width: 100%;"
            @change="handleCycle"
          >
            <a-select-option v-for="item in cycleOption" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <div v-for="key in selectDateList" :key="key">
          <!--        ['week', 'sky', 'day', 'hour', 'minute']-->
          <a-form-item v-if="key === 'week'">
            <a-select
              v-decorator="['week']"
              placeholder=""
              style="width: 80px; margin-right: 5px;"
            >
              <a-select-option v-for="item in weekOption" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!--          <a-form-item v-if="key === 'sky'">-->
          <!--            <a-input v-decorator="['sky', { rules: [{ required: true, message: '请填写' }] }]" type="number" addon-after="天" class="date-type"/>-->
          <!--          </a-form-item>-->
          <a-form-item v-if="key === 'day'">
            <a-input v-decorator="['day', { rules: [{ required: true, message: '请填写' }] }]" type="number" addon-after="日" class="date-type"/>
          </a-form-item>
          <a-form-item v-if="key === 'hour'">
            <a-input v-decorator="['hour', { rules: [{ required: true, message: '请填写' }] }]" type="number" addon-after="小时" class="date-type"/>
          </a-form-item>
          <a-form-item v-if="key === 'minute'">
            <a-input v-decorator="['minute', { rules: [{ required: true, message: '请填写' }] }]" type="number" addon-after="分钟" class="date-type"/>
          </a-form-item>
        </div>
      </div>
      <a-form-item label="备注">
        <a-textarea v-decorator="['parameter']" placeholder="请填写备注" :rows="4" />
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script>
  import { addBackDb, setTime } from '@/api/system/monitorjob'
  const fields = ['cycle', 'minute', 'hour', 'day', 'week', 'parameter']
  export default {
    name: 'TaskModal',
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      taskModalValue: {
        type: Object,
        default: () => {
          return {
            status: 'ADD'
          }
        }
      }
    },
    watch: {
      visible (n) {
        if (!n) return
        this.initData()
      }
    },
    data () {
      return {
        loading: false,
        form: this.$form.createForm(this),
        // dateTypeList: ['minute', 'hour', 'day', 'week']
        cycleOption: [
          {
            label: '每天',
            value: 1,
            dateTypeList: ['hour', 'minute'],
            // cronList: ['0', 'minute', 'hour', '*', '*']
            cronList: [
              { name: 'minute', value: null, func: (val) => { return val } },
              { name: 'hour', value: null, func: (val) => { return val } },
              { name: '*', value: '*' },
              { name: '*', value: '*' },
              { name: '*', value: '*' },
              { name: '?', value: '?' }
            ]
          },
          {
            label: 'N天',
            value: 2,
            dateTypeList: ['day', 'hour', 'minute'],
            // cronList: ['0', 'minute', 'hour', '*', '*']
            cronList: [
              { name: 'minute', value: null, func: (val) => { return val } },
              { name: 'hour', value: null, func: (val) => { return val } },
              { name: 'day', value: null, func: (val) => { return '0/' + val } },
              { name: '*', value: '*' },
              { name: '*', value: '*' },
              { name: '?', value: '?' }
            ]
          },
          {
            label: '每小时',
            value: 3,
            dateTypeList: ['minute'],
            cronList: [
              { name: 'minute', value: null, func: (val) => { return val } },
              { name: 'hour', value: null, func: (val) => { return '0/1' } },
              { name: '*', value: '*' },
              { name: '*', value: '*' },
              { name: '*', value: '*' },
              { name: '?', value: '?' }
            ]
          },
          {
            label: 'N小时',
            value: 4,
            dateTypeList: ['hour', 'minute'],
            cronList: [
              { name: 'minute', value: null, func: (val) => { return val } },
              { name: 'hour', value: null, func: (val) => { return '0/' + val } },
              { name: '*', value: '*' },
              { name: '*', value: '*' },
              { name: '*', value: '*' },
              { name: '?', value: '?' }
            ]
          },
          {
            label: 'N分钟',
            value: 5,
            dateTypeList: ['minute'],
            cronList: [
              { name: 'minute', value: null, func: (val) => { return '0/' + val } },
              { name: 'hour', value: null, func: (val) => { return '0' } },
              { name: '*', value: '*' },
              { name: '*', value: '*' },
              { name: '*', value: '*' },
              { name: '?', value: '?' }
            ]
          },
          {
            label: '每星期',
            value: 6,
            dateTypeList: ['week', 'hour', 'minute'],
            cronList: [
              { name: 'minute', value: null, func: (val) => { return val } },
              { name: 'hour', value: null, func: (val) => { return val } },
              { name: '*', value: '*' },
              { name: '*', value: '*' },
              { name: 'week', value: null, func: (val) => { return val } },
              { name: '?', value: '?' }
            ]
          },
          {
            label: '每月',
            value: 7,
            dateTypeList: ['day', 'hour', 'minute'],
            cronList: [
              { name: 'minute', value: null, func: (val) => { return val } },
              { name: 'hour', value: null, func: (val) => { return val } },
              { name: 'day', value: null, func: (val) => { return val } },
              { name: '*', value: '*' },
              { name: '*', value: '*' },
              { name: '?', value: '?' }
            ]
          }
        ],
        weekOption: [
          { label: '周一', value: '1' },
          { label: '周二', value: '2' },
          { label: '周三', value: '3' },
          { label: '周四', value: '4' },
          { label: '周五', value: '5' },
          { label: '周六', value: '6' },
          { label: '周日', value: '0' }
        ],
        cycleRowType: {},
        selectDateList: []
      }
    },
    mounted () {
      // 注册form
      fields.forEach(v => this.form.getFieldDecorator(v))
    },
    methods: {
      initData () {
        const defaultOne = this.cycleOption[0].value
        // 选中第一个值
        this.form.setFieldsValue({ cycle: defaultOne })
        // 展示日期项
        this.handleCycle(defaultOne)
        if (this.taskModalValue.status === 'UPDATE') {
          // 注册form
          fields.forEach(v => this.form.getFieldDecorator(v))
          this.form.setFieldsValue({ parameter: this.taskModalValue.value.executorParam })
          this.initEditData()
        }
      },
      // 初始化编辑的值（cron转化为展示可用字段）
      initEditData () {
        const cronTypeList = ['minute', 'hour', 'day', 'month', 'week']
        // 用空格分割
        const cronList = (this.taskModalValue.value.scheduleConf && this.taskModalValue.value.scheduleConf.split(' ')) || []
        // 匹配出选中的值
        const awaitAddValue = {}
        // cron类型值
        const selectedCronTypeList = []
        cronList.forEach((val, index) => {
          if ((val !== '*') && (val !== '?')) {
            // 匹配出选中的值
            awaitAddValue[cronTypeList[index]] = val.split('0/').pop()
            selectedCronTypeList.push(cronTypeList[index])
          }
        })
        // 判断两个数组相等
        let selectedVal = this.cycleOption.filter(item => {
          return item.dateTypeList.sort().toString() === selectedCronTypeList.sort().toString()
        })
        selectedVal = selectedVal.length > 0 ? selectedVal[0] : {}
        // 选中一个值
        this.form.setFieldsValue({ cycle: selectedVal.value })
        // 展示日期项
        this.handleCycle(selectedVal.value)
        this.form.setFieldsValue(awaitAddValue)
      },
      handleOk () {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.loading = true
            const addSubmit = this.setDataProcessing(values)
            if (this.taskModalValue.status === 'ADD') {
              addBackDb(addSubmit).then(res => {
                if (res.code === 200) {
                  this.$message.success('添加成功')
                  this.cancelForm(true)
                }
              }).finally(() => {
                this.loading = false
              })
            } else {
              setTime({
                id: this.taskModalValue.value.id,
                ...addSubmit
              }).then(res => {
                if (res.code === 200) {
                  this.$message.success('编辑成功')
                  this.cancelForm(true)
                }
              }).finally(() => {
                this.loading = false
              })
            }
          }
        })
      },
      /**
       * 处理data数据
       * @param values 传入data
       */
      setDataProcessing (values) {
        const selectList = this.cycleOption.filter(val => val.value === values.cycle)
        const selected = selectList.length > 0 ? selectList[0] : false
        if (!selected) return false
        const returnData = {
          // 调度类型 CRON / FIX_RATE
          scheduleType: 'CRON',
          // 调度值【必须】
          scheduleConf: '',
          // 任务参数(预留为详情)
          executorParam: values.parameter
        }
        let cronList = ''
        selected.cronList.forEach((item, index) => {
            if (selected.dateTypeList.some(val => val === item.name)) {
              cronList = index === 0 ? item.func(values[item.name]) : (cronList + ' ' + item.func(values[item.name]))
            } else {
              cronList = index === 0 ? item.value : (cronList + ' ' + item.value)
            }
        })
        // 生成cron字符串
        returnData.scheduleConf = cronList
        return returnData
      },
      /**
       * 关闭form
       * @param submit 是否有提交数据
       */
      cancelForm (submit = false) {
        this.form.resetFields()
        this.$emit('update:visible', false)
        this.$emit('cancel', { submit })
      },
      // 展示日期项
      handleCycle (selectedValue) {
        const selected = this.cycleOption.filter(val => val.value === selectedValue)[0]
        this.selectDateList = selected.dateTypeList
      }
    }
  }
</script>

<style scoped>
  .date-type {
    width: 120px;
    margin-right: 5px;
  }
</style>
