<template>
  <div>
    <a-card :bordered="false">
      <div class="header-options">
        <a-button type="primary" v-permission="['/timer/addBackDb']" @click="handleAdd">添加任务</a-button>
      </div>
      <!--      <div class="table-page-search-wrapper">-->
      <!--        <a-form layout="inline">-->
      <!--          <a-row :gutter="48">-->
      <!--            <a-col :md="8" :sm="24">-->
      <!--              <a-form-item label="菜单名称">-->
      <!--                <a-input v-model="queryParam.menuName" placeholder="请输入菜单名称"/>-->
      <!--              </a-form-item>-->
      <!--            </a-col>-->
      <!--            <a-col :md="8" :sm="24">-->
      <!--              <a-form-item label="使用状态">-->
      <!--                <a-select v-model="queryParam.status" placeholder="请选择" allowClear>-->
      <!--                  <a-select-option-->
      <!--                    v-for="item of statusOptions"-->
      <!--                    :key="item.value"-->
      <!--                    :value="item.value"-->
      <!--                  >-->
      <!--                    {{ item.label }}-->
      <!--                  </a-select-option>-->
      <!--                </a-select>-->
      <!--              </a-form-item>-->
      <!--            </a-col>-->
      <!--            <a-col :md="8" :sm="24">-->
      <!--              <a-button type="primary" @click="getMenuList">查询</a-button>-->
      <!--              <a-button style="margin-left: 8px">重置</a-button>-->
      <!--            </a-col>-->
      <!--          </a-row>-->
      <!--        </a-form>-->
      <!--      </div>-->

      <!--      <div class="table-operator">-->
      <!--        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>-->
      <!--      </div>-->
      <a-table :columns="columns" :data-source="tableList" rowKey="id">
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            title="确认执行吗?"
            @confirm="() => handleExecute(record.id)"
          >
            <a href="javascript:;" v-permission="['/timer/execute']">立即执行</a>
          </a-popconfirm>
          <a-divider type="vertical" v-permission="['/timer/start']"/>
          <a-popconfirm
            title="确认开启吗?"
            @confirm="() => handleOnTiming(record.id)"
          >
            <a href="javascript:;" v-permission="['/timer/start']">开启定时</a>
          </a-popconfirm>
          <a-divider type="vertical" v-permission="['/timer/stop']"/>
          <a-popconfirm
            title="确认关闭吗?"
            @confirm="() => handleStopTiming(record.id)"
          >
            <a href="javascript:;" v-permission="['/timer/stop']">停止定时</a>
          </a-popconfirm>
          <a-divider type="vertical" v-permission="['/timer/setTime']" />
          <!--          <a @click="() => handleSetTime(record)">设置</a>-->
          <!--          <a-divider type="vertical" />-->
          <a @click="() => handleUpdateTask(record)" v-permission="['/timer/setTime']">修改任务</a>
          <a-divider type="vertical" v-permission="['/timer/remove']"/>
          <a-popconfirm
            title="确认删除吗?"
            @confirm="() => handleDel(record.id)"
          >
            <a href="javascript:;" v-permission="['/timer/remove']">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <a-modal
      :width="650"
      title="设置定时"
      :visible="visible"
      :confirmLoading="loading"
      okText="确认"
      cancelText="取消"
      @ok="handleOk"
      @cancel="cancelForm"
    >
      <a-spin :spinning="loading">
        <!--        v-bind="formLayout"-->
        <a-form :form="form">
          <a-form-item label="间隔时间(秒)" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-input v-decorator="['timeout']" type="number" />
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
    <task-modal :visible.sync="taskVisible" :taskModalValue="taskModalValue" @cancel="handleCancelTask"></task-modal>
  </div>
</template>

<script>
  import TaskModal from './components/TaskModal'
  import { timerList, setTime, timerStart, timerStop, timerExecute, delTimer } from '@/api/system/monitorjob'
  const columns = [
    {
      title: '任务组',
      dataIndex: 'jobGroup'
    },
    {
      title: '备注名称',
      dataIndex: 'executorParam'
    },
    {
      title: '任务名称',
      dataIndex: 'jobDesc'
    },
    {
      title: '拥有者',
      dataIndex: 'author'
    },
    {
      title: '间隔时间（Cron）',
      dataIndex: 'scheduleConf'
    },
    {
      title: '状态',
      dataIndex: 'triggerStatusName'
    },
    {
      title: '操作',
      dataIndex: 'operation',
      scopedSlots: { customRender: 'operation' }
    }
  ]
  export default {
    components: {
      TaskModal
    },
    data () {
      return {
        columns,
        queryParam: {},
        statusOptions: [],
        tableList: [],
        rowInfo: {},
        // modal
        visible: false,
        loading: false,
        form: this.$form.createForm(this),
        taskVisible: false,
        taskModalValue: {}
      }
    },
    created () {
      this.getMenuList()
    },
    methods: {
      getMenuList () {
        timerList().then(res => {
          if (res.code === 200) {
            this.tableList = res.rows.map(val => {
              val.triggerStatusName = val.triggerStatus === 1 ? '开' : '关'
              return val
            })
          }
        })
      },
      handleExecute (id) {
        timerExecute({ id }).then(res => {
          if (res.code === 200) {
            this.$message.success('执行成功')
          }
        })
      },
      handleOnTiming (id) {
        timerStart({ id }).then(res => {
          if (res.code === 200) {
            this.$message.success('开启成功')
          }
        })
      },
      handleStopTiming (id) {
        timerStop({ id }).then(res => {
          if (res.code === 200) {
            this.$message.success('停止成功')
          }
        })
      },
      handleSetTime (row) {
        this.rowInfo = row
        this.visible = true
      },
      cancelForm () {
        this.visible = false
        this.form.resetFields()
      },
      async handleOk () {
        this.form.validateFields(async (err, values) => {
          if (!err) {
            this.loading = true
            try {
              const res = await setTime({
                id: this.rowInfo.id,
                timeout: values.timeout
              })
              if (res.code === 200) {
                this.$message.success('添加成功')
                this.cancelForm()
              }
            } catch (e) {
              console.error(e)
            }
            this.loading = false
          }
        })
      },
      // 添加任务
      handleAdd () {
        this.taskModalValue = {
          status: 'ADD'
        }
        this.taskVisible = true
      },
      handleCancelTask (value) {
        if (value.submit) return
        // 更新列表
        this.getMenuList()
      },
      // 修改任务
      handleUpdateTask (row) {
        this.taskModalValue = {
          status: 'UPDATE',
          value: row
        }
        this.taskVisible = true
      },
      handleDel (id) {
        delTimer({ id }).then(res => {
          if (res.code === 200) {
            this.$message.success('删除成功')
            // 更新列表
            this.getMenuList()
          }
        })
      }
    }
  }
</script>

<style scoped>
  .header-options {
    margin-bottom: 10px;
  }
</style>
