import request from '@/utils/request'

// 定时任务list
export function timerList (params) {
  return request({
    url: '/timer/list',
    method: 'get',
    params: params
  })
}

// // 设置定时
// export function setTime (params) {
//   return request({
//     url: '/timer/setTime',
//     method: 'post',
//     data: params
//   })
// }

// 开启定时
export function timerStart (params) {
  return request({
    url: '/timer/start',
    method: 'post',
    data: params
  })
}

// 停止定时
export function timerStop (params) {
  return request({
    url: '/timer/stop',
    method: 'post',
    data: params
  })
}

// 立即执行
export function timerExecute (params) {
  return request({
    url: '/timer/execute',
    method: 'post',
    data: params
  })
}

// 添加定时任务
export function addBackDb (params) {
  return request({
    url: 'timer/addBackDb',
    method: 'post',
    data: params
  })
}

// 删除
export function delTimer (params) {
  return request({
    url: 'timer/remove',
    method: 'post',
    params: params
  })
}

// 编辑定时任务
export function setTime (params) {
  return request({
    url: 'timer/setTime',
    method: 'post',
    data: params
  })
}
